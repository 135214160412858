.containerSignUp{
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.formSignUp{
    width: 50vw;
    height: 41.25vw;
    margin-top: 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../../Img/signup.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.inputName{
    margin-left: 15.5vw;
    margin-top: 6vw;
    font-size: 1.5vw;
    background-color: #E7A123;
    border: none;
    outline: 0;
    font-weight: 700;
    width: 25vw;
    color:#fff;
}
.inputName::placeholder {
    color: #fff;
}
.inputEmailSignUp{
    margin-left: 15.5vw;
    margin-top: 4.3vw;
    font-size: 1.5vw;
    background-color: #E7A123;
    border: none;
    outline: 0;
    font-weight: 700;
    width: 25vw;
    color:#fff;
}
.inputEmailSignUp::placeholder {
    color: #fff;
}
.inputPasswordSignUp{
    margin-left: 15.5vw;
    margin-top: 4vw;
    font-size: 1.5vw;
    background-color: #E7A123;
    border: none;
    outline: 0;
    font-weight: 700;
    width: 25vw;
    color:#fff;
}
.inputPasswordSignUp::placeholder {
    color: #fff;
}
.buttonSignUpSignUp{
    color: #fff;
    display: flex;
    justify-content: center;
    min-width: fit-content;
    background-color: #009C4A;
    font-size: 1.2vw;
    font-weight: 700;
    border: none;
    margin-left: 24vw;
    margin-top: 3.4vw;
    width: 17.5vw;
    padding: 1.1vw 0vw;
    border-radius: 7vw;
}
.buttonSignUpSignUp:hover{   
    cursor: pointer;
    transition: all 200ms ease;
    transform: scale(1.1);
}
@media (max-width: 1055px) {
    .formSignUp{
        width: 80vw;
        height: 66vw;
    }
    .inputName{
        margin-left: 26vw;
        margin-top: 9.5vw;
        font-size: 2.5vw;
        width: 39vw;
    }
    .inputEmailSignUp{
        margin-left: 26vw;
        margin-top: 6.5vw;
        font-size: 2.5vw;
        width: 39vw;
    }
    .inputPasswordSignUp{
        margin-left: 26vw;
        margin-top: 6.2vw;
        font-size: 2.5vw;
        width: 39vw;
    }
    .buttonSignUpSignUp{       
        font-size: 2.3vw;
        margin-left: 38.5vw;
        margin-top: 5.5vw;
        width: 27vw;
        padding: 1.3vw 0vw;
        border-radius: 7vw;
    }
  
}