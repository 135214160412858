.containerAbout{
    width: 100%;
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    justify-content: space-between;

}
.divTexts{
    margin-left: 5vw;
}
.pAbout{
    font-size: 1.2vw;
    font-weight: 700;
}
.bAbout{    
    font-size: 2.7vw;
    font-weight: 700;
    margin-bottom: 1vw;
    color:#009C4A;
}
.line{
    width: 75%;
    background-color: #EDB41E;
    height: .3vw;
    margin-bottom: 1vw;
}
.logoVivi{
    width: 5vw;
}
.exponor2022{
    margin: 3vw 0vw;
    width: 20vw;
}
.AboutImg{
    width: 53.5vw;
    margin-bottom: 2vw;
    margin-left: -3vw;
}
@media (max-width: 1055px) {
    .containerAbout{
        width: 100vw;
        display: flex;
        flex-direction: column;
    }
    .divTexts{
        margin: 1vw 0vw;
        align-self: center;
        display: flex;
        flex-direction: column;
    }
    .pAbout{
        font-size: 2vw;
    }
    .bAbout{    
        font-size: 4vw;
    }
    .line{
       height: .5vw;
       margin-bottom: 1vw;
    }
    .logoVivi{
        width: 7vw;
    }
    .exponor2022{
        width: 35vw;
    }
    .AboutImg{
        align-self: flex-end;
        width: 75vw;
    }

}