.containerMap{
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.mapImg{
    align-self: center;
    margin: 2.5vw 0vw;
    width: 80vw;
}
.spanMap{
    font-size: 2vw;
    font-weight: 900;
    color:#009C4A;
}
.buttonMap{
    text-decoration: none;
    color: #fff;
    background-color: #009C4A;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    text-decoration: none;
    padding: 1vw;
    font-size: 2vw;
    font-weight: 700;
    border-radius: 3rem;
    width: 25vw;
    margin: 2vw
}
.cursor{
    color:#EDB41E;
}
.buttonMap:hover {
    cursor: pointer;
    transition: all 300ms ease;
    transform: scale(1.1);
}

@media (max-width: 1055px) {
    .mapImg{
        width: 95vw;
        justify-self: center;
    }
    .spanMap{
        font-size: 4vw;
    }
    .buttonMap{
        font-size: 4vw;
        padding: 1vw;
        width: 50vw;
    }
    .cursor{
        margin-top: 1vw;
    }

}