.containerHome{
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.divHome{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
}
.ballon{
    width: 30vw;
    margin-right: 10vw;
}
.ballon:hover {
    cursor: pointer;
    transition: all 300ms ease;
    transform: scale(1.1);
}

@media (max-width: 1055px) {
    .divHome{
        flex-direction: column;
    }
    .ballon{
        width: 60vw;
        margin-right: 0vw;
    }

}
