.containerShows{
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.divShows{
    margin: 3vw 0vw;
    align-self: center;
    display: flex;
}
.div27{
    display: flex;
    flex-direction: column;
}
.divClick{
    margin:0;
    width: 100%;
    height: 100%;
    border-style: solid;
    border-color: #949599;
    border-top-width: 0;
    border-left-width: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.buttonShows{
    text-decoration: none;
    color: #fff;
    background-color: #F47222;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    text-decoration: none;
    padding: .5vw;
    font-size: 2vw;
    font-weight: 700;
    border-radius: 3rem;
    width: 15vw;
    margin: 2vw
}
.buttonShows:hover {
    cursor: pointer;
    transition: all 300ms ease;
    transform: scale(1.1);
}
@media (max-width: 1055px) {
    .divShows{
        margin: 10vw 0vw;
        display: flex;
        width: 99vw;
        justify-content: center;

    }
    .dia252628Img{
        width: 64vw;
        object-fit: contain;
    }
    .divDiv27{
        width: 32vw;
    }
    .dia27Img{
        width: 32vw;
    }
    .divinoImg{
        width: 28vw;
    }
    .buttonShows{
        width: 20vw;
        font-size: 2vw;
        padding: 1vw;
    }
}



