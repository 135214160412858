.containerExpositor{
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.titleExpositor{
    font-size: 3vw;
    font-weight: 900;
    color: #003F1B;
}
.divFrames{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 85vw;
    margin-bottom: 3.75vw;
}
.expositorLogo{
    width: 15vw;
    height: 10vw;
    border: #ECB126 solid .25vw;
    border-radius: 1vw;
    margin: 1vw 0 1vw 0;    
}
.textExpositor{
    font-size: 1.5vw;
    font-weight: 900;
    color: #003F1B;
}
.buttonExpositor{
    text-decoration: none;
    color: #fff;
    background-color: #009C4A;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    text-decoration: none;
    padding: .5vw;
    font-size: 1.5vw;
    font-weight: 700;
    border-radius: 3rem;
    width: 15vw;
    margin-bottom: 2vw;
}
.cursor{
    color:#EDB41E;
}
.buttonExpositor:hover {
    cursor: pointer;
    transition: all 300ms ease;
    transform: scale(1.1);
}
@media (max-width: 1055px) {
    .containerExpositor h1 {
        font-size: 6vw;
        margin-bottom: 2vw;
    }
    .containerExpositor p {
        width: 90%;
        text-indent: 1.5vw;
        text-align: justify;
        margin: 1vw 0vw
    }
}

