.countdown{
    display: flex;
    width: 30vw;
    height: 3.6vw;
}
.countdownText{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #999999;
    width: 40%;
    text-align: center;
    font-size: .75vw;
    color: #ffffff;
    font-weight: 700;
    border-bottom-left-radius: 3.6vw;
    border-top-left-radius: 3.6vw;
}
.countdownText div{
    background-color: 'transparent';
    width: 1vw;
    height: 1vw;
}
.timer{
    width: 60%;
    background-color: #4D4D4D;
    display: flex;
    align-items: center;
    justify-content: center;
}
.timerBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    color:#ffffff;
    margin: 0vw .5vw
    
}
.timerNumber{
    font-size: 1.5vw;
    font-weight: 700;
    color:#ffffff;
}
.timerName{
    font-weight: 500;
    font-size: .75vw;
}

@media (max-width: 1055px) {
    .countdown{
        align-self: flex-end;
        display: flex;
        width: 100%;
        height: 12vw;
    }
    .countdownText{
        font-size: 3vw;
    }
    .timerNumber{
        font-size: 5vw;
    }
    .timerName{
        font-size: 2vw;
    }
}