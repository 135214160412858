.newsContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 5vw;
}
.newsTitle{
    font-size: 1.5vw;
    background-color: black;
    font-weight: 700;
    color: #ECB512;
    padding: .5vw 3vw;
    border-top-right-radius: 1vw;
    border-top-left-radius: 1vw;
}
.newsDiv{
    background-color: #006A06;
    width: 100%;
    display: flex;
    padding: 3vw 0rem;
    justify-content: center;
    
}
.newsCard{
    display: flex;
    align-items: center;
    width: 25vw;
    height: 9vw;
    margin: 0vw 1vw
}
.newsImg{
    width: 12vw;
    height: 9vw;
    object-fit: cover;
}
.newsImg:hover {
    cursor: pointer;
    transition: all 300ms ease;
    transform: scale(1.1);
}
.newsRight{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    justify-content: space-between;
    margin-left: 1vw;
}
.newsText{
    text-indent: 1vw;
    text-align: justify;
    font-size: .9vw;
    font-weight: 500;
    color: white;
}
.newsButton{
    display: flex;
    justify-content: center;
    text-decoration: none;
    color: #ECB512;
    background-color: black;
    padding: .5vw;
    font-size: 1vw;
    font-weight: 500;
    border-radius: .5vw;
    width: 10vw;
}
.newsButton:hover {
    cursor: pointer;
    transition: all 300ms ease;
    transform: scale(1.1);
}
.newsCenter{ 
    height: 8vw;
    object-fit: fill;
    margin-top: 3vw;
}
@media (max-width: 1055px) {
    .newsDiv{
        flex-direction: column;
        align-items: center;
    }
    .newsTitle{
        font-size: 2.5vw;
        background-color: black;
        font-weight: 700;
        color: #ECB512;
        padding: .5vw 3vw;
        border-top-right-radius: 2vw;
        border-top-left-radius: 2vw;
    }
    .newsCard{
        display: flex;
        align-items: center;
        width: 60vw;
        height: 12vw;
        margin: 1vw 0vw;
    }
    .newsImg{
    width: 15vw;
    height: 12vw;
    object-fit: cover;
}
    .newsText{
        font-size: 2vw;
    }
    .newsButton{
        width: 50%;
        font-size: 2vw;
    }
}