*{
  text-decoration: none;
  margin:0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: "Carnas";
  src: url('../public/Carnas.ttf') format('truetype');
}
html{
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
  font-family: 'Carnas', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./Img/back.png');
  background-size: auto;
  height: 100%;
}
.footerContainer{
  flex-shrink: 0;
}

.spinner {
  animation: is-rotating 1s infinite;
  border: .5rem solid transparent;
  border-radius: 50%;
  border-top-color: #009D53;
  height: 2rem;
  width: 2rem;
  position: fixed;
  top:50%;
  left: 50%;
}
@keyframes is-rotating {
to {
transform: rotate(1turn);
}
}