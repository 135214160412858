.footerContainer{
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: black;
    height: 10vw;
    background-image: url('../../Img/finotrato.png');
    background-repeat: no-repeat;
    background-size: 1.5vw;
    background-position-x: 98%;
    background-position-y: center;
    
}
.footerLocal{
    width: 25vw;
    display: flex;
    flex-direction: column;
    color: white;
}
.spanTitle{
    color:#ECB512;
    font-size: 1.3vw;
}
.spanText{
    font-size: 1vw;
}
.footerLogo{
    width: 15vw;
    margin: 1rem 0rem;
}
.phone{
    margin: 0vw .5vw;
}
@media (max-width: 1055px) {    
    .spanTitle{
        font-size: 1.5vw;
    }
    .spanText{
        font-size: 1.5vw;
    }
    .phone{
        padding: 1vw;
    }
    .footerLogo{
        width: 20vw;
    }

}

    