.containerGallery{
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.galleryDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.title{
    color: #ECB512;
    background-color: black;
    font-size: 2vw;
    font-weight: 600;
    text-align: center;
    width: 20vw;
    padding: 1vw 0vw;
    border-radius: 1vw;
}
.galleryImg{
    width: 10vw;
    height: 10vw;
    margin: .5vw;
    border-radius: 1vw;
    object-fit: cover;
    cursor: pointer;
}
.photos{
    width: 95vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 1vw 0vw;
}
.buttonClose{
    background-color: transparent;
    border: none;
    align-self: flex-end;
    font-size: 2vw;
}
.buttonClose:hover {
    cursor: pointer;
    transition: all 300ms ease;
    transform: scale(1.1);
}
@media (max-width: 1055px) {
    .galleryImg{
        width: 20vw;
        height: 20vw;
        border-radius: 2vw;
    }
    .title{
        font-size: 4vw;      
        width: 40vw;
        padding: 2vw 0vw;
        border-radius: 4vw;
    }
    .buttonClose{
        font-size: 5vw;
    }
}