.containerSlider{  
    width: 95vw;
}

.swiper{
    padding: 1rem;
}
.swiper-slide{
    display: flex;
    justify-content: center;
}
.swiper-pagination-bullet-active {
    background-color: #ECB512;
    border-width: 1px;
    border-style: solid;
}
.swiper-button-prev {
    color: black;
}
.swiper-button-next {
    color: black
}
@media (max-width: 1055px) {
    .containerSlider{  
        z-index:0;
    }
 
    .swiper{
        padding: 0;
    }
    

}